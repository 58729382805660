import React from 'react'
import project1 from '../../assets/project1.PNG'
import project2 from '../../assets/project2.PNG'
import project3 from '../../assets/project3.PNG'
import './projects.css'

const projectData = [
  {
    id: 1,
    img: project1,
    title: 'Your Balance',
    github:'https://github.com/tatianaborda/your-balance'
  },
  {
    id: 2,
    img: project2,
    title: 'Crypto Aliens',
    github:'https://github.com/tatianaborda/crypto-aliens'
  },
  {
    id: 3,
    img: project3,
    title: 'To do API',
    github:'https://github.com/tatianaborda/todoAPI'
  }
]

const Projects = () => {
  return (
    <section id="projects">
      <h2>My recent projects</h2>
       <div className="container projects__container">
        {
          projectData.map(({id, img, title, github}) => {
            return (
              <article key={id} className="project">
              <h3>{title}</h3>
              <div className="project__img">
                <img src={img} alt={title}/>
              </div>
              <div className="project__cta">
              <a href={github} target="_blank" rel="noopener noreferrer">Github</a>
              </div>
              </article>
            )
          }
          )
        }
       </div>
    </section>
  )
}

export default Projects